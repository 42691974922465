@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";

:root {
  --fc-today-bg-color: #{rgba(theme-color('secondary'), 0.15)};
}

.dot {
  content: '';
  height: 1em;
  width: 1em;
  background-color: gray('300');
  border-radius: 50%;
  display: inline-block;
}

.fc {
  a:not([href]) {
    text-decoration: none;
  }

  .fc-list-table {
    hyphens: auto;
    word-break: break-word;
  }

  .fc-list-empty {
    padding: 0 5%;
    text-align: center;
  }

  &.loading {
    .fc-view-harness {
      position: relative;
      &:after {
        z-index: 10;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(gray('100'), 0.5);
      }
    }
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.75rem !important;
  }

  .fc-toolbar.fc-footer-toolbar {
    margin-top: 1rem !important;
  }

  .fc-today-button:disabled {
    display: none !important;
  }

  .fc-toolbar-title {
    font-size: 1.25em !important;
    font-weight: 500;
    line-height: 1.2;
  }

  .fc-timegrid-slot {
    height: 2rem !important;
  }

  .fc-event {
    @include transition($btn-transition);
    @include transition(opacity .15s ease-in-out);
    &.inactive {
      opacity: 0.35;
      @include hover-focus-active() {
        opacity: 0.5;
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.35;

      &.fc-timegrid-event {
        border-color: gray('700') !important;
        background-color: gray('700') !important;

        .fc-event-main {
          text-decoration: line-through;
        }
      }

      &.fc-list-event {
        text-decoration: line-through;
      }
    }

    /* Higher contrast stripes */
    &.progress-bar-striped {
      --stripe-color-1: rgba(255, 255, 255, .45);
      --stripe-color-2: rgba(255, 255, 255, .25);
      background-image: linear-gradient(
                      45deg,
                      var(--stripe-color-1) 25%,
                      var(--stripe-color-2) 25%,
                      var(--stripe-color-2) 50%,
                      var(--stripe-color-1) 50%,
                      var(--stripe-color-1) 75%,
                      var(--stripe-color-2) 75%,
                      var(--stripe-color-2)
      );
    }
  }

  &.fc-theme-bootstrap {
    .btn {
      @extend .btn-sm;
      &.btn-primary {
        background-color: transparent;
        @extend .btn-outline-primary;
      }
    }
  }
}
