@import "~bootstrap/scss/mixins";

.psp-selector {
  label {
    width: 100%;
    position: relative;
  }

  .checked-icon {
    @include transition($transition-fade);
    opacity: 0;
    visibility: hidden;
  }

  .card-input-element {
    position: absolute;
    bottom: 0;
    left: calc(50% - 6px);
    pointer-events: none;
    opacity: 0;

    & + .card {
      color: $text-muted;
      border: $input-border-width solid $input-border-color;
      cursor: pointer;
      @include transition($btn-transition);
    }

    &:checked + .card {
      @include transition($btn-transition);
      color: $primary;
      border-color: $primary;

      .checked-icon {
        opacity: 1;
        visibility: initial;
      }
    }
  }
}
