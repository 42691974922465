.gallery-container a img {
  max-width: 100%;
  height: auto;
}

.hero-video--thumb {
  img {
    border-radius: $border-radius;
  }

  &:hover {
    .play-overlay {
      width: 100px;
      height: 100px;
      top: calc(50% - 50px);
      left: calc(50% - 50px);

    }
  }
}

.play-overlay {
  background: #0000008f;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 35px);
  width: 70px;
  height: 70px;
  left: calc(50% - 35px);
  transition: all 0.3s;

  i {
    position: relative;
    left: 50%;
    transform: translate(-40%, -60%);
    top: 50%;
  }
}
