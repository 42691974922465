.accordion {
  button {
    &::after {
      content: "\f107";
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      float: right;
      color: $white;
    }
    &.collapsed {
      &::after {
        content: "\f105";
      }
    }
  }
}
