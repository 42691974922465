.video-hero-wrapper{
  position: relative;
  background: $secondary;
  @include media-breakpoint-up(lg) {
    height: 400px;
  }
}
.video-hero-overlay-text{
  position: relative;
  left:0;
  top: 0;
  z-index:200;
  color: #fff;
  @extend .container, .pt-5
}
.video-hero-overlay-opaque{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  z-index:150;
  background-color: #000000;
  width: 100%;
  height:100%;
  display: none;
}
.vimeo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
