$cc-bg: $black !default;
$cc-text: color-yiq($cc-bg) !default;
$cc-allow-variant: 'primary' !default;
$cc-btn-border-radius: $btn-border-radius !default;

.cc-revoke, .cc-window {
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;

  color: $cc-text;
  background-color: $cc-bg;
  @extend .shadow-lg;

  .cc-link {
    @extend .text-reset;
    padding: 0 .2em;
  }

  .cc-btn {
    @extend .btn;
    border-radius: $cc-btn-border-radius !important;
    &.cc-deny {
      @extend .btn-link, .text-reset;
    }
    &.cc-allow {
      @extend .btn-#{$cc-allow-variant};
    }
  }
}