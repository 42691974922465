/*
 * Example:
 * .bg-hover-success:hover {
 *   background-color: $success !important;
 * }
 */
@mixin bg-hover-variant($color) {
  @include hover {
    background-color: $color !important;
  }
}

@each $color, $value in $theme-colors {
  .bg-hover-#{$color} {
    @include bg-hover-variant($value);
  }
}
