// Custom styling for embedded spotler forms
.mpFormTable {
  list-style: none !important;
  padding: 0 !important;
  .mpFormField {
    input:not([type='checkbox']):not([type='radio']), textarea {
      @extend .form-control;
    }
    input[type='checkbox'], input[type='radio'] {
      @extend .form-check-input;
      margin-right: 8px;
    }
    ul {
      list-style: none !important;
      padding-left: 1.5em;
    }
  }
  .form-label {
    @extend p;
  }
  .mpQuestionTable {
    @extend .mb-3;
  }
  .mpFormLabel {
    @extend .form-label;
    @extend .mb-0;
  }
  .submitButton {
    @extend .btn;
    @extend .btn-primary;
  }
  .error {
    @extend .form-text;
    @extend .text-danger;
  }
}

.mpErrorSummary {
  @extend .alert;
  @extend .alert-danger;

}
