@import "~bootstrap/scss/mixins";

.timepick {
  label {
    width: 100%;
    position: relative;
  }

  .card-input-element {
    &:checked + .btn {
      @include transition($btn-transition);
      color: white;
      background-color: $primary;
    }
  }
}
