// Extended bootstrap utilities

@import "utils/links";
@import "utils/bg-hover";

.w-md-100 {
  @include media-breakpoint-up (md) {
    width: 100% !important;
  }
}

.w-sm-auto {
  @include media-breakpoint-up(sm) {
    width: auto !important;
  }
}

.w-lg-auto {
  @include media-breakpoint-up (lg) {
    width: auto !important;
  }
}

.w-md-25 {
  @include media-breakpoint-up (md) {
    width: 25% !important;
  }
}

.bg-md-none {
  @include media-breakpoint-up (md) {
    background: none !important;
  }
}

.bg-lg-white {
  @include media-breakpoint-up (lg) {
    background: $white !important;
  }
}

.position-lg-static {
  @include media-breakpoint-up (lg) {
    position: static !important;
  }
}

.position-lg-relative {
  @include media-breakpoint-up (lg) {
    position: relative !important;
  }
}

.d-lg-contents {
  @include media-breakpoint-up(lg) {
    display: contents !important;
  }
}

.col--max {
  max-width: 1140px;
}

.page-fill {
  min-height: 62vh;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.rounded-top-left {
  border-top-left-radius: $border-radius;
}

.rounded-top-right {
  border-top-right-radius: $border-radius;
}

.rounded-bottom-left {
  border-bottom-left-radius: $border-radius;
}

.rounded-bottom-right {
  border-bottom-right-radius: $border-radius;
}

.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.end-0 {
  right: 0;
}

.flex-grow-2 {
  flex-grow: 2 !important
}

.flex-basis-100 {
  flex-basis: 100% !important
}

.bold {
  font-weight: bold !important;
}

.regular {
  font-weight: 500 !important;
}

.w-0 {
  // flex reset for equal size
  width: 0 !important;
}

.hyphens {
  hyphens: auto !important;
}

.d-grid {
  display: grid !important;
}

.d-contents {
  display: contents !important;
}

.d-table-caption {
  display: table-caption !important;
}

.g-stack {
  grid-area: 1/-1;
}

.z-r1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 99 !important;
}

.z-3 {
  z-index: 250;
}

.h-0 {
  height: 0 !important;
}

.inset-0 {
  inset: 0 !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-clip {
  overflow-x: clip !important;
}

.position-lg-absolute {
  @include media-breakpoint-up(lg) {
    position: absolute;
  }
}
