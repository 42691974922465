$primary: #00778b !default;
$secondary: #f3e85c !default;
$dark: #163A46 !default;
$light: #F6F6F6 !default;
$theme-1: $primary !default;
$theme-2: $secondary !default;
$theme-3: #DEECEF !default;
$transparant-primary: rgba(0, 119, 139, 0.5);

$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2,
        'theme-3': $theme-3,
        'secondary-light': $theme-3,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1950px,
        xxxl: 2250px
);

//========================
// general theme options
//========================
$footer-header-color: $secondary !default;
$card-link-color: $primary !default;
$link-decoration: underline;
$border-radius: 7px; // radius for cards, images and buttons
$line-height-base: 1.6;

//========================
//define text properties
//========================
@import url("https://use.typekit.net/kzm0hmj.css");
$font-family-base: 'canada-type-gibson', sans-serif;
$headings-font-weight: 600;
$headings-color: $dark;
$display-font-weight: 600;
$font-weight-bold: 600;

//========================
//define button properties
//========================
$btn-font-weight: 600;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-footer-style: theme-2;

//========================
//define banner spots properties
//========================
$accordion-banner-bg-color: primary; // Ect: theme-1, primary, white
$accordion-banner-text-color: white;

//========================

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

@import '../../../../src/scss/main.scss';

$display-font-weight: 600 !default;

@import url("https://use.typekit.net/zhh7wih.css");
@import '../../../../src/scss/components/hero-video.scss';

* {
  font-family: sofia-pro, sans-serif;
}

html {
  scroll-behavior: smooth;
}

.hero--banner {
  overflow-x: hidden;
}

h1, h2, .display-3, .display-4, .h1, .h2 {
  font-family: neo-sans, sans-serif;
  font-style: italic;
}

.h3, .h4, .h5, h3, h4, h5 {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 800;
}

.page--header {
  @include media-breakpoint-down(md) {
    font-size: 1.8em;
  }
}

.cta--table {
  table {
    @extend .m-0;
    td {
      @extend .border-0;
      vertical-align: middle !important;
      @extend .text-white;
    }
  }
}

.hero-cta {
  .display-3 {
    text-transform: uppercase;
    font-weight: 800;
    transform: rotate(-5deg);
    transform: skew(0deg, -7deg);
    line-height: 0.85em;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.56);
    @include media-breakpoint-down(sm) {
      font-size: 3.2em;
    }
    @include media-breakpoint-down(xs) {
      font-size: 2.6em;
    }
  }
}

.alert--text {
  p {
    @extend .d-contents;
  }
}

.general--banner {
  h1 {
    text-transform: uppercase;
  }
}

.account-button--style {
  @extend .btn-secondary;
  font-size: 1em !important;
}

.footer--menu {
  a {
    @extend .text-dark;
  }
}

.social-icons {
  a {
    @extend .mr-2;
    font-size: 1.3em;
  }
}

.rental-scheduler {
  label {
    @extend .text-dark;
  }
  input {
    height: auto;
  }
}

.jumbotron {
  background-color: $light;
}

#tickets {
  h3 {
    @extend .mb-4;
  }
}

.ticket-list-item--inner {
  @extend .border-0;
  img {
    @extend .rounded;
  }
  .card-title {
    @extend .mb-3;
  }
  .card-subtitle {
    font-weight: 400;
    @extend .text-primary;
  }
  .ticket-list-item--inner-spacing {
    @extend .px-0;
    @extend .mt-1;
    @extend .pt-2;
    .btn {
      &:not(:hover) {
        @extend .text-dark;
        @extend .border-primary;
      }
      @extend .btn-block;
      .fas {
        display: none;
      }
    }
  }
}

.navbar-brand {
  @extend .my-auto;
  .navbar-brand--img {
    height: 70px;
    @include media-breakpoint-down(sm) {
      height: 50px;
    }
  }
}

.menu--main {
  border-bottom: solid 1px $primary;
}

.breadcrumb--bar {
  @extend .bg-theme-3;
  a {
    text-decoration: none;
    @extend .text-dark;
  }
}

.part-category-nav {
  a {
    text-decoration: none;
    @extend .text-dark;
  }
}

.navbar--menu {
  .nav-search {
    .nav-link {
      @extend .pr-2;
    }
  }
  .mobile-hamburger-color {
    @extend .text-primary;
    @extend .pr-0;
  }
  .hamburger-box > span, .hamburger-box > span:before, .hamburger-box > span:after {
    @extend .bg-primary;
  }
  @extend .px-0;
  .navbar-nav {
    a {
      @extend .text-dark;
    }
  }
}
.btn--special {
  @extend .position-relative;
  @extend .font-italic;
  background: none !important;
  border: none !important;
  &::before {
    content: '';
    @extend .bg-theme-2;
    @extend .w-100;
    @extend .h-100;
    @extend .position-absolute;
    margin-left: -20px;
    margin-top: -10px;
    transform: skew(-26deg, 0deg);
    border-radius: 15px 0 15px 0;
  }
}

.alert--btn {
  @include media-breakpoint-down(md) {
    margin-top: 7px;
  }
}

.top-menu {
  .ul--skew {
    @extend .position-relative;
    a {
      @extend .text-dark;
    }
    &::before {
      content: '';
      @extend .bg-theme-3;
      transform: skew(-26deg, 0deg);
      @extend .w-100;
      @extend .h-100;
      @extend .position-absolute;
      margin-left: -20px;
      margin-top: -10px;
      border-radius: 0 0 15px 0;
    }
  }
  @extend .p-0;
  @extend .justify-content-end;
  ul {
    @extend .py-2;
    @extend .ml-0;
    @extend .my-0;
    @extend .d-flex;
    @extend .pr-lg-2;
    a {
      text-decoration: none !important;
    }
    li {
      @extend .z-0;
    }
    .nav-item {
      @extend .my-auto;
      @extend .ml-2;
      @extend .d-block;
    }
  }
}

.btn-outline, .btn-outline-primary {
  border: solid 2px;
}

.btn-outline-primary:not(:hover, .active) {
  color: $dark !important;
  border-color: $primary !important;
}
.btn:not(:hover, .active) {
  .fa-chevron-right {
    color: $primary !important;
  }
}

.pagination {
  .page-item:not(.active) {
    a {
      color: $dark !important;
      border: solid 1px $primary;
    }
  }
}

.pagination--nav {
  @extend .mx-auto;
  @extend .my-3;
}

.bg-gradient {
  background: rgb(30, 52, 59);
  background: linear-gradient(90deg, rgba(30, 52, 59, 100) 0%, rgba(30, 52, 59, 0.20) 50%, rgba(30, 52, 59, 100) 100%);
}

.quick-menu {
  .img--hover:hover {
    .fa-chevron-right {
      transform: translateX(5px);
    }
  }
  margin-top: -30px;
  .col-12 {
    height: fit-content;
  }

  img {
    @extend .btn-outline;
    border-color: white;
    @include media-breakpoint-up(md) {
      aspect-ratio: 1.7;
    }
  }

  @extend .text-white;
}

#Rooster, .rooster {
  thead {
    th {
      @extend .bg-white;
      @extend .border-0;
      @extend .text-primary;
      border-bottom: solid 2px $theme-2 !important;
    }
  }
}

.btn:not(.accordion-banner--btn) {
  font-size: 0.9em;
  padding: 0.6em 1.5em;
}

.btn-outline-secondary, .btn-outline-theme-1, .btn-outline-theme-2, .btn-outline-theme-3 {
  @extend .btn-outline;

  &:not(:hover) {
    @extend .text-dark;
  }
}

.agenda--filters, .news--filters {
  @extend .mb-4;
  @extend .pb-1;

  .btn:not(.active) {
    @extend .btn-outline-theme-3;
  }
}

.bg-primary-05 {
  background-color: $transparant-primary;
  border-radius: 0 0 $border-radius $border-radius;

  .text-theme-2 {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}


.btn-outline-theme-3 {
  border-color: $theme-3 !important;
}

.title--page {
  display: none;
}

#main-content {
  @extend .mb-4;
}

.accordion {
  .card-header:not(:has(.collapsed)) {
    @extend .bg-theme-3;
  }

  button::after {
    @extend .text-primary;
  }

  button:focus {
    box-shadow: none !important;
  }

  .card-body {
    border: solid 1px $light;
  }
}

.subhome--flavour {
  margin-bottom: -20px;
}

.subhome--logo {
  width: 400px !important;
}

footer {
  a {
    text-decoration: none;
  }

  .container--watermark {
    @extend .d-md-flex;
    @extend .p-0;

    .watermark {
      @extend .text-primary;
      opacity: 1 !important;
    }
  }
}

.opacity-1 {
  opacity: 0.12;
  filter: blur(2px);
  @extend .h-100;
  img {
    object-fit: cover;
  }
}

.flavour--img {
  top: 50%;
  transform: translateY(-50%);
  width: 250px;
}

.flavour--img-header {
  @extend .bottom-0;
  @extend .w-100;
  z-index: 0;
}

.flavour--img-duo {
  @extend .bottom-0;
}

.flavour--img {
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  @include media-breakpoint-down(md) {
    opacity: 0.7;
  }
}

.flavour--bow-left {
  transform: translate(-103%, -35%);
  width: 760px;
  @include media-breakpoint-up(xxl) {
    transform: translate(-123%, -35%);
  }
  @include media-breakpoint-up(xxxl) {
    transform: translate(-143%, -35%);
  }
}

.quick-navigation--banner {
  @extend .p-3;
  @extend .rounded;

  .fa {
    @extend .text-primary;
  }

  a {
    @extend .text-dark;
    font-weight: 400;
  }
}

.flavour--bow-right {
  transform: translate(103%, -35%);
  @extend .right-0;
  width: 760px;
  @include media-breakpoint-up(xxl) {
    transform: translate(123%, -35%);
  }
  @include media-breakpoint-up(xxxl) {
    transform: translate(143%, -35%);
  }
}

.suggestion-slider {
  .owl-dots {
    @extend .text-center;

    .owl-dot {
      @extend .text-primary;
      border: solid $primary;
      @extend .m-1;
      width: 12px;
      height: 12px;
      border-radius: 10px;

      &.active {
        background: $primary;
      }
    }
  }

  .review--img {
    aspect-ratio: 1 !important;
    object-fit: cover;
    width: 100px !important;
    border-radius: 60px !important;
    @include media-breakpoint-down(md) {
      width: 50px !important;
    }
  }
  .owl-stage-outer {
    padding-left: 40px;
    @include media-breakpoint-down(md) {
      padding-left: 26px;
    }
  }

  .owl-nav {
    @extend .w-100;
    top: 45%;
    transform: translateY(-50%);
    @extend .position-absolute;

    .prev, .next {
      @extend .btn-link;
      @extend .text-primary;
      @extend .border-0;
      background: none;
      @extend .text-decoration-none;
      font-size: 2em;
      @include media-breakpoint-down(md) {
        font-size: 1.3em;
      }
    }

    .next {
      transform: translateX(110%);
      @extend .right-0;
      @extend .position-absolute;
      margin-top: 4px;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }
  }
}

#main-content {
  table {
    @extend .table;
    thead {
      @extend .bg-light;
    }
  }
}

.header--img {
  margin-bottom: -50px;
}

.divider--height {
  height: 50px;
  display: list-item;
}

.media--title {
  font-weight: 400;
  color: $dark;
}

.media--img {
  width: 50px;
}

.media--arrow {
  width: 35px;
}

.cta--img {
  width: 80px;
  border-radius: 100px;
  aspect-ratio: 1;
  object-fit: cover;
}

.my--inner {
  @extend .d-flex;

  p {
    @extend .my-auto;
  }
}

.dropdown-toggle-split {
  @extend .pl-2;
}

.account-dropdown--style {
  .login--button:not(.dropdown-toggle) {
    @extend .pr-1;
  }
}

.cta--nudge {
  .media--arrow {
    transition: transform .3s;
  }
  &:hover {
    .media--arrow {
      transform: translateX(10px);
    }
  }
}

.top-bar--item {
  @extend .nav-item;
  a {
    @extend .mx-1;
  }
}

.btn, .card-stretch-hover {
  .fa-chevron-right {
    transition: transform .3s;
  }
  &:hover {
    .fa-chevron-right {
      transform: translateX(5px);
    }
  }
}

.btn--float {
  position: absolute;
  margin-top: 27px;
  right: 40px;
  @include media-breakpoint-down(sm) {
    margin-top: 7px;
  }
}

.img--hover {
  img {
    transition: transform .3s;
  }
  &:hover {
    img {
      transform: translateY(-10px);
    }
  }
}

.card {
  border-radius: $border-radius !important;
}

.card-stretch-hover {
  .card-img-top {
    transition: transform .3s;
  }
  .card-img-bg {
    object-fit: cover;
    transition: transform .3s;
  }
  &:hover {
    .card-img-top, .card-img-bg {
      transform: scale(1.1);
    }
  }
}

.ani-easeIn {
  animation: easeIn 1s linear;
}
.ani-moveUp {
  animation: moveUp 1s linear;
}
.ani-moveDown {
  animation: moveDown 1s linear;
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes moveDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes easeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.sidebar {
  .h4 {
    @extend .text-white;
  }
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  .invalid-feedback, .was-validated {
    color: $theme-3;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 0rem;
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
    .list-group-item {
      &.active {
        background: $secondary;
        border-color: $secondary;
        @extend .text-dark;
      }
    }
  }
}

#main-content {
  min-height: 55vh;
  @extend .position-relative;
}

.content-wrapper {
  @extend .pt-4;
}
.flex-fill, .wrapper {
  overflow: unset !important;
}
